import React, { useEffect, useMemo } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./CSS files/projectspage.css";

const Projectpage = ({ projects }) => {
  const { projectId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectId]);

  const project = useMemo(
    () => projects.find((proj) => proj.id.toString() === projectId),
    [projects, projectId]
  );

  const { previousProject, nextProject } = useMemo(() => {
    const currentIndex = projects.findIndex(
      (proj) => proj.id.toString() === projectId
    );
    return {
      previousProject: currentIndex > 0 ? projects[currentIndex - 1] : null,
      nextProject:
        currentIndex < projects.length - 1 ? projects[currentIndex + 1] : null,
    };
  }, [projects, projectId]);

  if (!project) {
    return <Navigate to="*" />;
  }
  const pageVariants = {
    initial: { opacity: 0, y: 100 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -100 },
  };

  const pageTransition = {
    type: "tween",
    ease: "easeInOut", // smooth easing function
    duration: 1, // extending the duration for smoother animation
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <section className="projects-page">
        <div className="project-header">
          <h2>{project.title}</h2>
          <p className="description-prj">{project.description}</p>
        </div>

        <div className="main-project-image">
          <img src={project.image} alt={project.title} />
        </div>

        <div className="project-content-wrapper">
          <div className="project-content">
            <div className="project-main">
              <h3>About</h3>
              <p>{project.about}</p>
              <h3>Work done</h3>
              <p>{project.solutions}</p>
              <p>{project.solutions2}</p>
              <p>{project.solutions3}</p>
            </div>

            <div className="project-meta">
              <div className="meta-item">
                <h4>MY ROLE</h4>
                <p>{project.role.join(", ")}</p>
              </div>
              <div className="meta-item">
                <h4>YEAR</h4>
                <p>{project.year}</p>
              </div>
              <div className="meta-item">
                <h4>INDUSTRY</h4>
                <p>{project.industry}</p>
              </div>
              <div className="meta-item">
                <h4>TARGET AUDIENCE</h4>
                <div className="audience-container">
                  {project.targetaudience.map((audience, index) => (
                    <div key={index} className="audience-item">
                      {audience}
                    </div>
                  ))}
                </div>
              </div>

              <div className="meta-item">
                <h4>TAGS</h4>
                <div className="tags-container">
                  {project.tags.map((tag, index) => (
                    <span key={index} className="tag">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
              {project.website && (
          <Link
            to={project.website}
            className="visit-website"
            target="_blank"
            rel="noopener noreferrer"
          >
            {project.cta} 
          </Link>
        )}
            </div>
          </div>
        </div>

        <div className="gallery-container-wrapper">
          <div className="gallery-container">
            {project.gallery.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Gallery ${index + 1}`}
                className="gallery-image"
              />
            ))}
          </div>
        </div>

        <div className="navigation-links-wrapper">
          <div className="navigation-links">
            {previousProject && (
              <Link to={`/projects/${previousProject.id}`} className="nav-link">
                Previous: {previousProject.title}
              </Link>
            )}
            {nextProject && (
              <Link to={`/projects/${nextProject.id}`} className="nav-link">
                Next: {nextProject.title}
              </Link>
            )}
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Projectpage;
