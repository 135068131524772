import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbarr";
import Homepage from "./components/Homepage";
import ProjectPage from "./Projectpage"; // Ensure the correct path
import projects from "./Projects";
import Loader from "./components/Loaderr"; // Ensure the correct path
import Footer from "./components/Footer";
import ErrorBoundary from "./ErrorBoundary";
import Pagenotfound from "./components/Pagenotfound";
import About from "./components/About";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      // Optionally, store the event for later use
      // deferredPrompt = e;
    });
  }, []);

  return (
    <div className="App">
      <ErrorBoundary>
        {loading ? (
          <Loader setLoading={setLoading} />
        ) : (
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/about" element={<About />} />
              {/* <Route path="/projects" element={<Projects />} /> */}

              <Route
                path="/projects/:projectId"
                element={<ProjectPage projects={projects} />}
              />
              <Route path="*" element={<Pagenotfound />} />
            </Routes>
            <Footer />
          </Router>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default App;
