
const OtherWorks = [
    {
      name: "Identify",
      image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753959/117_brsyeh.png',
    },

    {
      name: "Freelancer",
      image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753893/112_prpfrm.jpg',
    },
    {
      name: "Market Run",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753941/120_jtwzyv.png',
      },
      {
        name: "Raft",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753892/115_ahgubq.png',
      },
    //   6-10
    {
      name: "Market Run",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753942/118_zhgqnt.png',
      },
      {
        name: "Freelancer",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753887/113_dll2gz.jpg',

      },
      {
        name: "CSD",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721754311/128_xknrxy.png',
      },
      {
        name: "Finance Fast",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753974/125_bqxoa7.jpg',
        
      },
      {
        name: "Raft",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753944/116_svb7il.png',
        
      },
      {
        name: "Market Run",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753974/123_d7i1hj.png',
        
      },
      {
        name: "CSD",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721755736/129_fj179v.png',
      },

  ];
  
  export default OtherWorks;
  