import React, { useState, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import "../CSS files/Loader.css";

const Loaderr = ({ setLoading }) => {
  const [state, setState] = useState({
    progress: 0,
    currentLanguageIndex: 0,
    isLoaded: false,
  });

  const languages = useMemo(
    () => ['Hello', 'Bonjour', 'Ẹ n lẹ', 'Hola'],
    []
  );

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setState(prevState => {
        const nextProgress = prevState.progress + 1;
        
        if (nextProgress >= 100) {
          clearInterval(progressInterval);
          setTimeout(() => setLoading(false), 300);
          return { ...prevState, progress: 100, isLoaded: true };
        }

        return {
          ...prevState,
          progress: nextProgress,
        };
      });
    }, 50);

    const languageInterval = setInterval(() => {
      setState(prevState => ({
        ...prevState,
        currentLanguageIndex: (prevState.currentLanguageIndex + 1) % languages.length,
      }));
    }, 1000);

    return () => {
      clearInterval(progressInterval);
      clearInterval(languageInterval);
    };
  }, [setLoading, languages.length]);

  if (state.isLoaded) return null;

  return (
    <motion.div
      className="loader-container"
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      aria-live="polite"
      aria-busy={!state.isLoaded}
    >
      <div className="loader">
        <motion.p
          className="greeting-text"
          key={languages[state.currentLanguageIndex]}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {languages[state.currentLanguageIndex]}
        </motion.p>
        <motion.p
          className="progress-text"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
        >
          [{state.progress}%]
        </motion.p>
      </div>
    </motion.div>
  );
};

export default Loaderr;