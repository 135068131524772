import React from "react";
import "../CSS files/About.css";
// import bookCover from "../images/avatar.png";
// import podcastCover from "../images/galavant.png";
// import showCover from "../images/PODCAST.png";
// import hobbyCover from "../images/francais.png";
import myphoto from "../images/about-me.png";
import { motion } from "framer-motion";

const About = () => {
  // const favorites = [
  //   {
  //     type: "FAVOURITE SHOW",
  //     title: "Favourite Show",
  //     subtitle: "ATLA",
  //     image: bookCover,
  //   },
  //   {
  //     type: "FAVORITE PODCAST",
  //     title: "Favorite Podcast",
  //     subtitle: "Business Movers",
  //     image: showCover,
  //   },
  //   {
  //     type: "CURRENTLY LEARNING",
  //     title: "Currently Learning",
  //     subtitle: "Français",
  //     image: hobbyCover,
  //   },
  //   {
  //     type: "FAVORITE ACTIVITY",
  //     title: "Favorite Activity",
  //     subtitle: "Gallivanting or taking pictures",
  //     image: podcastCover,
  //   },
  // ];

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 20,
        duration: 1.5,
      },
    },
  };

  return (
    <motion.div
      className="about-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <section className="about-intro">
        <img src={myphoto} alt="Profile" className="drop-cap-image" />
        <p>
          Hi, I'm Adéọlá. My name is of Yoruba origin and means "crown of
          wealth". I'm a product designer based in Lagos, Nigeria. While my
          professional focus is on design, I consider myself a creative at
          heart as it has always shaped my perspective and approach to life.
        </p>
        <p>
          My journey into the world of product design began three years ago, but
          my creative pursuits extend far beyond that. Prior to transitioning to
          product design, I spent two years in marketing, an experience that has
          profoundly influenced my approach to understanding user needs.
        </p>
        <p>
          Currently, I work as a Product Designer at Tale, where I focus on
          designing innovative B2B products. My role involves advocating for
          users, conducting market research, analyzing data, and making
          strategic design decisions. I'm part of a community dedicated to
          improving user experiences, and I view design not just as a
          profession, but as a way of life that infuses every interaction and
          creation.

        </p>
        <p>
          When I'm not immersed in the world of product design, I explore
          various other forms of creative expression, drawing inspiration from
          the vibrant culture and dynamic energy of Lagos.
        </p>
      </section>

      <section className="skills-section">
        <h3>Skills</h3>
        <ul className="skills-grid">
          <li>UI/UX Design</li>
          <li>Research</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>Animations</li>
          <li>Illustrations</li>
          <li>Design Systems</li>
          <li>Marketing Strategy</li>
          <li>Prototyping</li>
        </ul>
      </section>

      <section className="contact-moi">
        <h3>Contact</h3>
        <div className="contact-div">
          <a href="mailto:adeolaadekoya11@gmail.com">
            Adeolaadekoya11@gmail.com
          </a>
          <p>Email address</p>
        </div>
        <div className="divider"></div>
        <div className="contact-div">
          <a
            href="https://www.linkedin.com/in/adeolaadekoya/"
            rel="noreferrer noopener"
            target="_blank"
          >
            linkedin.com/in/adeolaadekoya
          </a>
          <p>LinkedIn</p>
        </div>
        <div className="divider"></div>
        <div className="contact-div">
          <a
            href="https://www.behance.net/adeolaadekoya"
            rel="noreferrer noopener"
            target="_blank"
          >
            behance.net/adeolaadekoya
          </a>
          <p>Behance</p>
        </div>
        <div className="divider"></div>
        <div className="contact-div">
          <a
            href="https://x.com/adeoladev"
            rel="noreferrer noopener"
            target="_blank"
          >
            @adeoladev
          </a>
          <p>Twitter</p>
        </div>
        <div className="divider"></div>
        <div className="contact-div">
          <a
            href="https://www.figma.com/@adeolaadekoya"
            rel="noreferrer noopener"
            target="_blank"
          >
            @adeolaadekoya
          </a>
          <p>Figma Community</p>
        </div>
      </section>

      {/* <div className="favorites-section">
        <h2>Random Facts</h2>
        <div className="favorites-grid">
          {favorites.map((item, index) => (
            <div key={index} className="favorite-card">
              <img
                src={item.image}
                alt={item.title}
                className="favorite-image"
              />
              <div className="favorite-content">
                <h3 className="favorite-title">{item.title}</h3>
                {item.subtitle && (
                  <p className="favorite-subtitle">{item.subtitle}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className="spotify-embed">
        <h2>What I'm listening to</h2>
        <iframe
          src="https://open.spotify.com/embed/playlist/4FqZ9NEhxxENGtqPVhKjfj?utm_source=generator&theme=0"
          width="50%"
          height="460"
          title="Spotify Playlist"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </motion.div>
  );
};

export default About;
