import React from "react";
import { useNavigate } from "react-router-dom";
import "../CSS files/pagenotfound.css";

const Pagenotfound = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div>
      <section className="error-page">
        <div className="error-centered">
          <p>
           I think you're lost 🥲
          </p>
          <button className="back-to-projects contact" onClick={handleBackToHome}>
            Get back on track
          </button>
        </div>
      </section>
    </div>
  );
};

export default Pagenotfound;
