const Projects = [
  {
    id: 1,
    title: "Quickpay",
    description: "Enabling employees to request salary advances before payday.",
    tags: ["APP DESIGN", "WEB APP DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1720184211/quiaackpay-one_ydmyi3.png",
    link: "/projects/quickpay",
    about:
      "QuickpayNG is a comprehensive platform designed to enhance financial well-being for employees. It empowers workers to access a portion of their earned wages in real time, providing flexibility and alleviating the need to wait until payday, especially in times of financial emergencies.",
    solutions:
      "I designed both the mobile and web applications for QuickPay, encountering several challenges throughout the process. One key issue was figuring out how to ensure employees repaid the funds they borrowed. To address this, a finance section was introduced, allowing employers to view the total amount used by their employees, total earned wages, and transaction history. ",
    solutions2:
      "Additionally, a feature was implemented to prevent employees from borrowing more than they earn.For the employee app, given that QuickPay targets employees from various backgrounds, we focused on making the sign-in process intuitive and straightforward. We also added features to help employees understand their financial standing. This included an insights page showing where most of their money went, how much of their salary had been withdrawn, and what they could expect at the end of the month. ",

    solutions3:
      "To further support their financial well-being, we included options for checking their credit score and an educational section on financial literacy.An admin dashboard was also created to enable QuickPay administrators to monitor the number of enrolled companies, track disbursed funds, view total employees, enrolled employees, and much more.The project is currently live.",
    year: "2023",
    industry: "Fintech",
    targetaudience: ["Employees", "Employees", "Human Resources"],
    role: ["Product Designer", "Researcher"],
    gallery: [
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721609949/Slide_16_9_-_62_anhhiu.jpg",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721609965/Slide_16_9_-_61_xxw1df.jpg",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721609965/Slide_16_9_-_63_ovlscp.jpg",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721610479/Slidse_16_9_-_65_eq09qg.jpg",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721610482/Slidse_16_9_-_66_fssjqb.jpg",
    ],
    cta: "VISIT THE WEBSITE →",
    website: "https://quickpayng.com/auth/create-account",
  },
  {
    id: 2,
    title: "Biltapay",
    description:
      " Centralize your finances and get reminders to never miss a payment. ",
    tags: ["WEB DESIGN", "APP DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1720182441/bltapayy1_cv0zzw.png",
    link: "/projects/biltapay",
    about:
      "Biltapay offers a centralized platform to organize, track, and pay all your bills in one place, making it easy to manage your payments and avoid late fees.",
    solutions:
      "I worked on the website and mobile app for Biltapay alongside a team of designers, addressing key features to manage and schedule recurring bills. One of the main functionalities was to set reminders for upcoming bills, ensuring users never miss a payment. We also included features for tracking and managing bills by allowing users to enter relevant details such as biller name, due date, and amount.",
    solutions2:
      "The design process emphasized user-friendly interfaces and intuitive navigation, ensuring that users could effortlessly keep track of their financial obligations. This project aimed to simplify bill management and enhance the overall user experience.",

    solutions3:
      "",
    year: "2023",
    industry: "Fintech",
    targetaudience: ["General Consumers", "Tech-Savvy Consumers"],
    role: ["Product Designer", "Researcher"],
    gallery: [
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721638761/Slide_16_cjrrsz9_-_67_z0deqt.jpg",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721638755/Slidiice_16_9_-_70_fndxoe.jpg",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721638749/Slideee_16_9_-_69_erwdng.jpg",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721638730/Slideaoc_16_9_-_68_ooqe59.jpg",
    ],
    cta: "VISIT THE WEBSITE →",
    website: "https://biltapay.com/",
  },
  {
    id: 3,
    title: "Safe Seller",
    description: "One-stop shop to discover and connect with trusted vendors",
    tags: ["UX RESEARCH", "APP DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1720181672/safesellernew_td4ork.png",
    link: "/projects/safeseller",
    about:
      "Safe Seller is a passion project inspired by my online shopping experiences. To address the challenge of verifying store legitimacy and customer experiences, I developed an app that consolidates my thorough vetting process. Safe Seller allows users to discover vendors by location or category, explore detailed profiles, and read about other users' shopping experiences, making it easier to find trustworthy stores",
    solutions:
      "I designed both the website and mobile app for Safe Seller. The goal was to create a seamless platform where users can easily discover vendors by location or category, explore detailed vendor profiles, and read about other users' shopping experiences. The website and app feature a user-friendly interface and intuitive navigation, ensuring that users can quickly find and verify trustworthy stores. ",
    solutions2:
      "The app is currently in development.",

    solutions3:
      "",
    year: "2024",
    industry: "Business Directory",
    targetaudience: [
      "Online Shoppers",
      "Busy Professionals",
      "Tech-Savvy Users",
      "Review Enthusiasts",
      "Small Business Owners",
    ],
    role: ["Product Designer", "Researcher"],
    gallery: [
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721607570/Slidde_16_9_-_8_twn5rw.png",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721607622/Slidde_16_9_-_9_eqphku.png",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721607681/Slidde_16_9_-_12_e6zptr.png",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721608671/Slideb_16_9_-_13_gu2lae.png",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721608078/Slside_16_9_-_10_cabih7.png",
    ],

  },
  {
    id: 4,
    title: "British Museum",
    description:
      "A revamped British Museum App for a more immersive learning experience.",
    tags: ["UX RESEARCH", "APP DESIGN"],
    image:
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1720035587/Frame_1000001755_cmgfpb.png",
    link: "/projects/britishmuseum",
    about:
      "The British Museum is renowned for its vast collection with over eight million artefacts.Fascinated with the British museum, i thought of a way to get an interactive tour without visiting however, when i downloaded the app i found it restrictive and confusing. I decided to redesigning its app aims to provide visitors with an immersive learning experience, facilitating easy exploration and interaction with exhibits for enhanced learning and enjoyment",
    solutions:
      "My overhaul introduces a paywall and shopping section, allowing users to purchase artifact replicas and providing a fresh income source for the museum. I've also implemented an intuitive image-based search feature, enabling users to find artifacts by simply uploading pictures. To improve navigation, I've organized the exhibitions by continents, making it easier for visitors to explore and locate specific collections. The addition of virtual tours offers an experience akin to visiting the museum in person. To further assist users, I wrote a script and used AI to voice it, creating a virtual tour guide that helps navigate the museum floors. These innovations aim to make the app more engaging and user-friendly while opening up new financial opportunities for the British Museum.",
    solutions2:
      "",

    solutions3:
      "",
    year: "2023",
    industry: "Education",
    targetaudience: ["Tourists", "Students", "Educators"],
    role: ["Tourists", "Students", "Educators"],
    gallery: [
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721601721/Slide_16_9_-_41_cbusgj.png",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721601722/Slide_16_9_-_42_sumfx2.png",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721601765/Slide_16_9_-_40_wtwkki.png",
      "https://res.cloudinary.com/dyqg8luud/image/upload/v1721601730/Slide_16_9_-_39_ubvgpp.png",
    ],
    cta: "READ MORE →",
    website: "https://www.behance.net/gallery/165176351/British-Museum-App-Redesign-UIUX-Casestudy",
  },
  // {
  //   id: 5,
  //   title: "Tale",
  //   description:
  //     "Helping SaaS businesses manage their recurring subscriptions.",
  //   tags: ["UX RESEARCH", "WEB DESIGN", "DESIGN SYSTEM"],
  //   image:
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1720561378/taleimage_fcjwkk.png",
  //   link: "/projects/tale",
  //   about:
  //     "Tale is a B2B platform that allows SaaS founders manage their subscriptions as well as gain non dilutive funding for their business allowing them grow on their terms.",
  //   challenges:
  //     "Lorem ipsum dolor sit amet consectetur. Cras gravida arcu egestas euismod risus.Lorem ipsum dolor sit amet consectetur. Cras gravida arcu e",
  //   solutions:
  //     "Lorem ipsum dolor sit amet consectetur. Cras gravida arcu egestas euismod risus.Lorem ipsum dolor sit amet consectetur. Cras gravida arcu e",
  //   year: "Ongoing",
  //   industry: "SaaS",
  //   targetaudience: ["SaaS Founders", "Investors"],
  //   role: ["Product Designer", "Researcher"],
  //   gallery: [
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1720026935/cld-sample-4.jpg",
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1720026936/cld-sample-5.jpg",
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1720026934/cld-sample-3.jpg",
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1720026936/cld-sample-5.jpg",
  //     "https://res.cloudinary.com/dyqg8luud/image/upload/v1720026898/samples/food/fish-vegetables.jpg",
  //   ],
  // },
];

export default Projects;
